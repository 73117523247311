import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Link } from "gatsby";
import SEO from "../components/seo";

const Invite = (props) =>{
  const [key, setKey] = useState(null)
  let invite_id = props.params.id;

  useEffect(() => {
      setKey(invite_id);
      localStorage.setItem("invite_code",invite_id);
      navigate('/');
    },[]);

  return null;

}
export default Invite;
